import React, { useEffect } from "react";
import "./TermsAndPolicy.css"; // Import the CSS file for styling

const TermsAndPolicy = () => {

  useEffect(() => {
    // Scroll to the top of the page
     window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-container">
      <h1> תקנון\תנאי השימוש</h1>
      <div className="terms-content">
        <p>
          השימוש באתר הניידים (להלן: "האתר") המנוהל על ידי שותפות הניידים, ע.מ.
          558570834 (להלן: "הקמעונאי") כפוף לתנאי השימוש המפורטים להלן (להלן:
          "תנאי השימוש") ולמדיניות הפרטיות המפורסמת באתר (להלן: "מדיניות
          הפרטיות").
        </p>
        <p>
          תנאי השימוש מנוסחים בלשון זכר אך מיועדים לכל אדם, נשים וגברים כאחד. על
          ידי גלישה באתר ו/או שימוש באתר ו/או הסתמכות על מידע המופיע באתר, לרבות
          רכישת מוצרים ו/או שירותים המוצעים באתר, הינך מסכים ומתחייב לתנאים
          המפורטים להלן.
        </p>
        <p>
          הקמעונאי שומר לעצמו את הזכות לעדכן את תנאי השימוש מעת לעת ולפי שיקול
          דעתו הבלעדי וללא צורך בהודעה מראש. תוקפו של כל שינוי ייכנס לתוקף מרגע
          פרסומו באתר, וזוהי חובתך להתעדכן בתנאים אלו בכל כניסה לאתר.
        </p>
        <p>
          אם אינך מסכים לתנאי השימוש ומדיניות הפרטיות של האתר, כולם או חלקם,
          אינך רשאי לעשות שימוש באתר לכל מטרה שהיא.
        </p>
        <p>
          מובהר בזאת כי האתר מיועד למשתמשים מעל גיל 18 בלבד, ובאישור תנאי השימוש,
          הנך מצהיר כי הנך בגיר, מעל גיל 18, וכי הנך בעל כשירות משפטית ולא
          הוכרזת כפסול דין.
        </p>

        <h2>1. שירותי האתר</h2>
        <p>
          1.1 האתר הינו מרכז הקניות המקוון של הקמעונאי עבור מוצרי אלכוהול, ציוד
          לברים ניידים ומוצרים משלימים אחרים המוצעים למכירה ו/או להשכרה, בכפוף
          לתנאי השימוש, בו תוכל לערוך חיפוש, רכישה והזמנת משלוחים לאזורים בידי
          הקמעונאי או לאסוף את הזמנתך באופן עצמאי ממיקום האיסוף הייעודי (להלן:
          "השירותים").
        </p>
        <p>
          1.2 שירות ההזמנה המקוון באתר נגיש לכל רחבי מדינת ישראל. יש לציין כי גם
          אם הזמנה כזו נקלטה במערכת, הקמעונאי לא יהיה מחויב לספקה.
        </p>
        <p>
          1.3 האתר נועד לשימוש פרטי בלבד וכל שימוש מסחרי בו ללא קבלת הסכמה מראש
          ובכתב של הקמעונאי הינו אסור. כמו כן, אינך רשאי להשתמש בתכנים שמקורם
          באתר ו/או בצד שלישי. הקמעונאי יהיה רשאי להפסיק את שימושך באתר בכל עת
          אם לא תעמוד באחד או יותר מתנאי השימוש ו/או מכל סיבה שהיא, וללא הודעה
          מוקדמת ולפי שיקול דעת הבלעדי.
        </p>
        <p>
          1.4 השימוש בשירותים המוצעים על ידי האתר והגישה אליהם בטלים ככל ששימוש
          או גישה זו אסורה לפי החוק. שימושך באתר יחשב כהצהרתך והתחייבותך כי (א)
          כל מידע לרישום יבשיר ומדויק; (ב) תשמור על עדכניות ואמיתות המידע שהוגש;
          (ג) אתה בן 18 ומעלה; ו-(ד) השימוש שלך בשירותים אינו מפר כל חוק או תקנה
          או כל התחייבות אחרת.
        </p>
        <p>1.5 השירותים ו/או צורת האתר עשויים להשתנות מעת לעת, ללא הודעה מוקדמת.</p>

        <h2>2. הרשמה לאתר</h2>
        <p>
          2.1 כדי להשתמש בשירותי האתר תתבקש לספק לקמעונאי מספר פרטים אודותיך
          בטופס רישום, לרבות טלפון ופרטי כרטיס אשראי. חלק מהנתונים שתתבקש למסור
          הינם פרטי חובה אשר יסומנו באופן מפורש ככאלה, ובלעדיהם לא ניתן להירשם
          לאתר ו/או לבצע הזמנה ו/או להשתמש בשירותי האתר.
        </p>
        <p>
          2.2 על מנת להשתמש בשירותי האתר תתבקש למסור את פרטי החובה על גבי טופס
          הרישום ולבחור סיסמה, אשר תשמש אותך לצורך זיהוי וקבלת השירותים. הנך
          מתחייב: (א) כי ידוע לך שאתה אחראי באופן מלא לשמירת סודיות הסיסמה; (ב)
          להודיע לקמעונאי באופן מיידי על כל שימוש שאינו מורשה בסיסמה שלך ו/או על
          כל הפרת סודיות אחרת.
        </p>
        <p>
            2.3. הקמעונאי לא יהיה אחראי לשימוש בלתי מורשה של צד כלשהו (לרבות צד ג') בנתונים שנמסרו על ידי משתמש.
        </p>
        <p>
         2.4. ההרשמה וההזמנה באתר הינה לבגירים מגיל 18 שנים בלבד.
        </p>
      
        <h2>3. המוצרים הנמכרים ו/או המושכרים באתר</h2>
        <p>
            3.1. באתר נמכרים מוצרי אלכוהול, שתיה קלה, מוצרים חד פעמיים נלווים ומוצרים לברים ניידים, ומוצרים נוספים כמפורט ברשימות המוצרים המופיעות באתר.
        </p>
        <p>
            3.2. הקמעונאי רשאי לשנות בכל עת, לפי שיקול דעתו הבלעדי, את ההיצע ו/או מגוון המוצרים המוצגים ברשימת המוצרים באתר. יובהר, כי תמונות המוצרים המופיעות באתר הינן להמחשה בלבד ואינן מחייבות את הקמעונאי.
        </p>
        <p>
            3.3. יובהר, כי הרכישה באתר היא ללקוחות פרטיים בלבד, ולא תאפשר מכירה סיטונאית של המוצרים. הקמעונאי שומר לעצמו את הזכות למנוע ביצוע רכישות והזמנות המהוות רכישה סיטונאית.
        </p>
        <p>
            3.4. רכישת משקאות אלכוהוליים מותרת אך ורק ללקוחות אשר גילם מעל 18 שנים. בעת הזמנת מוצר מסוג כזה אתם מאשרים כי הנכם בוגרים ורשאים לרכוש מוצרים אלו על פי חוק.
        </p>
        
        <h2>4. מחירי המוצרים</h2>
        <p>
            4.1. הקמעונאי מציג באתר את מחירי המוצרים השונים ואת מחיר דמי המשלוח לפי שיקול דעתו הבלעדי. יובהר, כי מחירי המוצרים המופיעים באתר כוללים מס ערך מוסף.
        </p>
        <p>
            4.2. הקמעונאי אינו מתחייב כי מחירי המוצרים באתר יהיו זהים למחירי המוצרים הנמכרים בסניפי הקמעונאי השונים, ובכל מקרה המחיר הקובע למוצרים המסופקים באמצעות השירות הינו המחיר המופיע באישור ההזמנה ולא המחיר המופיע במדבקת המחיר.
        </p>
        <p>
            4.3. הקמעונאי רשאי לעדכן את מחירי המוצרים המופיעים באתר מעת לעת ללא קשר למועד ביקור המשתמש באתר ו/או עריכת ההזמנה. המחיר הקובע יהיה המחיר במועד ביצוע ההזמנה.
        </p>
        <p>
            4.4. מוצרים המוצגים למכירה במחירי מבצע יסופקו במחיר המבצע בכפוף לכך שהמבצע בתוקף במועד אישור ההזמנה הסופית.
        </p>
        {/* Section 5 */}
        <h2>5. ביצוע ההזמנה והתשלום</h2>
            <p>5.1. תשלום באמצעות כרטיס אשראי - התשלום באתר יתבצע באמצעות כרטיס אשראי תקף אשר הונפק על ידי אחת מחברות האשראי המוכרות הפועלות בישראל, המאפשרות ביצוע עסקאות מקוונות, וזאת בכפוף לתנאים החלים על סוג כרטיס האשראי שברשות הלקוח.</p>
            <p>5.2. תשלום באמצעות אפליקציות תשלום - יתאפשר תשלום באמצעות אפליקציית BIT ו/או PAYBOX. אישור ההזמנה יישלח ללקוח לאחר קליטת הסכום במערכת המוכר באמצעות האפליקציה הרלוונטית, כפי שיופיע במערכת BIT ו/או PAYBOX של המוכר.</p>
            <p>5.3. תשלום באמצעות העברה בנקאית - יתאפשר ביצוע תשלום באמצעות העברה בנקאית לחשבון הבנק של הקמעונאי, כפי שפרטיו יפורטו באתר. אישור ההזמנה יישלח ללקוח לאחר בדיקת האסמכתא לביצוע ההעברה הבנקאית, שתועבר לקמעונאי באמצעות האמצעים המפורטים באתר.</p>
            <p>5.4. הקמעונאי אינו אחראי ולא יהיה אחראי לכל טענה בדבר אי נכונות ו/או אי דיוק ו/או פגמים בפרטים שנמסרו על ידיך בעת הביצוע של ההזמנה ו/או טענה אחרת בקשר עם ביצוע הזמנה ו/או משלוח השירות ו/או לגבי פרטים שלא נקלטו במערכת של הקמעונאי ולכל נזק, חיסרון כיס, הנובעים מהאמור לעיל.</p>
            <p>5.5. הקמעונאי שומר לעצמו את הזכות לקבוע הסדרי תשלומים אחרים ו/או אמצעי תשלום נוספים ככל שיבחר לכך לפי שיקול דעתו הבלעדי, לרבות הסדרים לתשלום באמצעים שונים מכרטיסי אשראי.</p>
            <p>5.6. תנאי האשראי אצל קמעונאי יהיו בהתאם לתנאים הנהוגים אצל חברות האשראי ומספר התשלומים האפשרי יוצגו לאישורך בעת אישור ההזמנה.</p>
            <p>5.7. הקמעונאי יהיה רשאי להגביל את כמות כל סוגי המוצרים ו/או מספר יחידות פר מוצר ו/או מוצרים מקסימאלי עבור הזמנה.</p>
            <p>5.8. הקמעונאי אינו חייב לספק הזמנות שסכומן הכולל, בעת השלמת ההזמנה, אינו עולה על סכום מינימאלי של 99 ₪ וכפי שיקבע לאזורי המשלוח וזמני אספקתו.</p>

            {/* Section 6 */}
            <h2>6. דמי משלוח</h2>
            <p>6.1. ההזמנה באתר תחויב בתשלום דמי משלוח בסכום שיצוין באתר, בהתאם לאזור המשלוח וטווח הזמנים המבוקש בהזמנה. יובהר כי דמי המשלוח הינם תשלום נוסף על תשלום ההזמנה ואינם תשר או תשלום אישי לשליח ו/או לכל גורם אחר המעורב בתהליך השילוח, ולא יועבר באופן אישי לאף אחד מהם.</p>
            <p>6.2. סכום דמי המשלוח יצורף לחשבון הסופי ויוצג בפני הלקוח בעת האישור הסופי של פרטי ההזמנה.</p>
            <p>6.3. הקמעונאי שומר לעצמו את הזכות לעדכן את דמי המשלוח מעת לעת על פי שיקול דעתו הבלעדי ואינו מתחייב להודיע מראש על שינוי ו/או עדכון כאמור.</p>

            {/* Section 7 */}
            <h2>7. אספקת המוצרים</h2>
            <p>7.1. המוצרים והמצרכים הנכללים בהזמנה יסופקו באמצעות שליח (להלן: "משלוח").</p>
            <p>7.2. מובהר בזאת כי המוצרים יסופקו ב-"תאריך וזמן המשלוח" שבחר הקונה ועד ל-4 שעות ממועד זה.</p>
            <p>7.3. משלוחים יסופקו לקומת קרקע בלבד.</p>
            <p>7.4. המוצרים הנכללים בהזמנה יסופקו למען שיימסר על ידי הלקוח במועד ההרשמה או למען שיפורט בטופס פרטי המשלוח. מועדי המשלוח המוצעים באתר כפופים ליכולת האספקה של הקמעונאי לפי שיקול דעתו הבלעדי. על הלקוח להיות נוכח בכתובת על מנת ששליחי הקמעונאי יוכלו למסור לך את המשלוח. שליח הקמעונאי יחייג וימתין בכתובת למשך 10 דק', במידה ולא יהיה מענה לאחר הזמן המצויין יוחזר המשלוח לסניף והלקוח יחוייב בדמי המשלוח המלאים.</p>
            {/* Section 8 */}
            <h2>8. ביטול ושינוי הזמנה</h2>
            <p>8.1. ניתן לשנות או לבטל הזמנה עד 24 שעות לפני מועד האספקה שנבחר. השינויים יבוצעו באמצעות פנייה לשירות הלקוחות באמצעים המפורטים באתר.</p>
            <p>8.2. במקרה של ביטול הזמנה לאחר תחילת הכנת המשלוח, ייתכן שהלקוח יחויב בתשלום עבור השירותים שכבר בוצעו.</p>
            <p>8.3. הקמעונאי שומר לעצמו את הזכות לבטל הזמנה אם יתברר כי נפלה טעות בפרטי ההזמנה, במחירים, או מכל סיבה אחרת, על פי שיקול דעתו הבלעדי.</p>

            {/* Section 9 */}
            <h2>9. החזרת מוצרים</h2>
            <p>9.1. החזרת מוצרים תתאפשר בכפוף לחוק הגנת הצרכן בישראל, ובלבד שהמוצרים לא נפתחו, לא נפגמו ולא נעשה בהם שימוש.</p>
            <p>9.2. מוצרים מסוג מזון, משקאות או מוצרים מתכלים אחרים אינם ניתנים להחזרה, למעט אם התגלה פגם במוצר.</p>
            <p>9.3. החזרת מוצרים תבוצע על ידי הלקוח, בהתאם להנחיות הקמעונאי, ובאחריותו להחזיר את המוצרים למקום שנקבע.</p>

            {/* Section 10 */}
            <h2>10. קניין רוחני</h2>
            <p>10.1. כל התכנים המופיעים באתר, לרבות טקסטים, תמונות, לוגואים, גרפיקה ותכנים אחרים, מוגנים בזכויות יוצרים ואינם ניתנים לשימוש ללא אישור בכתב מראש מהקמעונאי.</p>
            <p>10.2. חל איסור מוחלט להעתיק, להפיץ, לשנות או לפרסם את תכני האתר, באופן מלא או חלקי, ללא הסכמה מפורשת מהקמעונאי.</p>

            {/* Section 11 */}
            <h2>11. הגבלת אחריות ושיפוי</h2>
            <p>11.1. הקמעונאי לא יהיה אחראי לנזקים ישירים או עקיפים הנובעים משימוש באתר או בשירותיו, לרבות עיכובים באספקת המוצרים או טעויות במידע שהוזן על ידי המשתמש.</p>
            <p>11.2. הלקוח מתחייב לשפות את הקמעונאי בגין כל נזק, תביעה או דרישה מצד שלישי, הנובעים מהפרת תנאי שימוש אלה.</p>

            {/* Section 12 */}
            <h2>12. התחייבות הגולשים באתר</h2>
            <p>12.1. המשתמש מתחייב לספק מידע מדויק ונכון בעת הרישום וביצוע ההזמנה.</p>
            <p>12.2. חל איסור מוחלט לבצע שימוש באתר לצרכים בלתי חוקיים, לרבות שימוש בכרטיסי אשראי גנובים או מידע מזויף.</p>

            {/* Section 13 */}
            <h2>13. פרסומות של צדדים שלישיים, קישורים לאתרים אחרים</h2>
            <p>13.1. האתר עשוי לכלול פרסומות או קישורים לאתרים חיצוניים. הקמעונאי אינו אחראי לתכנים, שירותים או מוצרים המוצעים באתרים אלה.</p>
            <p>13.2. השימוש באתרים חיצוניים הוא באחריות המשתמש בלבד, והוא מתבקש לקרוא את תנאי השימוש והמדיניות של כל אתר אליו הוא נכנס.</p>

            {/* Section 14 */}
            <h2>14. שונות</h2>
            <p>14.1. תנאים אלה מהווים את ההסכם המלא בין המשתמש לבין הקמעונאי בכל הנוגע לשימוש באתר.</p>
            <p>14.2. הקמעונאי שומר לעצמו את הזכות לשנות את תנאי השימוש מעת לעת, והגרסה המעודכנת תהיה זמינה באתר.</p>
            <p>14.3. כל מחלוקת בין המשתמש לבין הקמעונאי תתברר בהתאם לחוקי מדינת ישראל ובבתי המשפט המוסמכים באזור תל אביב בלבד.</p>
            
      </div>
    </div>
  );
};

export default TermsAndPolicy;
