import React, { useEffect, useState } from 'react'
import './popular.css'
import { Item } from '../Item/Item'
import { ItemType } from '../../Models/ItemModel'
import { getpopularProducts } from '../../Services/productServices'


export const Popular  = () => {

  const [popularProducts, setPopularProducts] = useState<ItemType[]>([]);

  const fetchPopularProducts = async () =>{
    const popularProducts = await getpopularProducts();
    setPopularProducts(popularProducts);
    console.log(popularProducts);
  }

  useEffect(() => {
    fetchPopularProducts();
  }, [])

  return (
    <div className='popular'>
        <h1>המוצרים המובילים שלנו</h1>
        <hr />
        <div className="popular-item">
            {popularProducts.map((item, i) => {
                return <Item key={i} 
                id={item.id} 
                name={item.name} 
                image={item.image} 
                new_price={item.new_price} 
                old_price={item.old_price}/>
            })}
        </div>
    </div>
  )
}
