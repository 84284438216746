import React, { useState, useEffect } from "react";
import "./AgeVerificationPopup.css"; // Import the CSS file for styling
import logo from '../Assets/logo_for_productdisplay.svg';


const AgeVerificationPopup = () => {
  const [isVerified, setIsVerified] = useState(false);

  const handleVerify = () => {
    setIsVerified(true);
    localStorage.setItem("ageVerified", "true");
  };

  const handleReject = () => {
    alert("אתה צריך להיות מעל 18 כדי שתוכל לבקר באתר הזה, תודה!");
    window.location.href = "https://google.com"; // Redirect to another page
  };

  // Check localStorage to avoid showing the popup again
  useEffect(() => {
    const ageVerified = localStorage.getItem("ageVerified");
    if (ageVerified === "true") {
      setIsVerified(true);
    }
  }, []);

  if (isVerified) {
    return null; // Don't show the popup if verified
  }

  return (
    <div className="overlay">
      <div className="popup">
        <img src={logo} className="hanayadeem-logo" />
        {/* <h2>הצהרה</h2> */}
        <p className="over-age">כניסה לאתר מותרת רק לאנשים שמלאו להם 18 </p>
        <div className="button-container">
          <button onClick={handleVerify} className="btn accept">
            מאשר שמלאו לי 18
          </button>
          <button onClick={handleReject} className="btn reject">
 !אחזור לבקר בעתיד</button>
        </div>
        <p className="warnning"> אזהרה: צריכה מופרזת של אלכוהול מסכנת חיים ומזיקה לבריאות</p>
      </div>
    </div>
  );
};

export default AgeVerificationPopup;
