import React from 'react'
import './footer.css'
import footer_logo from '../Assets/logo_new16.png'
import instagram_icon from '../Assets/instagram_icon.png'
import pintrest_icon from '../Assets/pintester_icon.png'
import whatsapp_icon from '../Assets/whatsapp_icon.png'
import { FaFacebook } from "react-icons/fa6";
import { FaGoogle } from "react-icons/fa";
import { SocialIcon } from 'react-social-icons'
import { useNavigate } from 'react-router-dom'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import zezo_logo from '../Assets/zezo-logo.svg';

export const Footer = () => {
  
  const navigate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>, path: string) {
    // event.preventDefault();
    navigate(path);
  }

  return (
    <div className='footer'>
      {/* <hr/> */}
      <div className='footer-top'>
        <div className="footer-social-media">
          <p>בואו לעקוב אחרינו</p>
          <div className="footer-social-icons">
            <div className="footer-icons-container">
                {/* <img src={instagram_icon} alt="" /> */}
                <SocialIcon url="https://instagram.com" />
            </div>
            <div className="footer-icons-container">
                {/* <FaFacebook size={25}/> */}
                <SocialIcon url="https://facebook.com" />
            </div>
            <div className="footer-icons-container">
                {/* <img src={whatsapp_icon} alt="" /> */}
                <SocialIcon url="https://whatsapp.com" />
            </div>
            <div className="footer-icons-container">
                {/* <FaGoogle size={25} /> */}
                <SocialIcon url="https://google.com" />
            </div>
          </div>
        </div>
        <ul className="footer-links">
          {/* <li>צור קשר</li> */}
          <li>חוות דעת</li>
          <li onClick={(e) => handleClick(e, "/אודות")}>? מי אנחנו</li>
          <li onClick={(e) => handleClick(e, "/תקנון")}>תקנון החברה</li>
          <li onClick={(e) => handleClick(e, "")}>דף הבית</li>
        </ul>
        <div className="contact-us">
          <div className="phone">
            <div className="phone-numbers">
              <p>050- 828 5 818</p>
            </div>
            <PhoneIcon/>
          </div>
          <div className="mail">
            <p>support@hanayadeem.co.il</p>
            <EmailIcon/>
          </div>
        </div>
        <div className="footer-logo" onClick={(e) => navigate("/")}>
          {/* <img src={footer_logo} alt="" /> */}
          <p className='eng-name'>HaNAYADEEM</p>
          {/* <hr/> */}
          <p>הניידים</p>
        </div>
      </div>
      <div className="footer-copyright">
        <img src={zezo_logo} alt="" />
        <p>זכויות יוצרים @ 2024 - כל הזכויות שמורות</p>

      </div>
    </div>
  )
}
